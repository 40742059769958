var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "ul",
      { staticClass: "nav nav-tabs", attrs: { id: "myTab", role: "tablist" } },
      _vm._l(this.getYears, function(year, index) {
        return _c(
          "li",
          { staticClass: "nav-item", attrs: { role: "presentation" } },
          [
            _c(
              "button",
              {
                staticClass: "nav-link",
                class: index == 0 ? "active" : "",
                attrs: {
                  id: _vm.getYearButtonId(year),
                  "data-bs-toggle": "tab",
                  "data-bs-target": _vm.getTarget(year),
                  type: "button",
                  role: "tab",
                  "aria-controls": _vm.getTabId(year),
                  "aria-selected": index == 0 ? true : false
                }
              },
              [_vm._v(_vm._s(year))]
            )
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tab-content", attrs: { id: "priceTabContent" } },
      _vm._l(this.getYears, function(year, index) {
        return _c(
          "div",
          {
            staticClass: "tab-pane fade",
            class: index == 0 ? "show active" : "",
            attrs: {
              id: _vm.getTabId(year),
              role: "tabpanel",
              "aria-labelledby": _vm.getTabAreaLabel(year)
            }
          },
          [
            _c(
              "table",
              { staticClass: "table pricelist-table table-hover" },
              _vm._l(_vm.priceArray, function(sg) {
                return _c(
                  "tbody",
                  [
                    _c(
                      "tr",
                      { class: { mainrow: sg.lines && sg.lines.length > 0 } },
                      [
                        _c("td", [
                          _vm._v(
                            "\n                                " +
                              _vm._s(sg.service.name) +
                              "\n                                "
                          ),
                          _c("small", [
                            sg.nightRange
                              ? _c("span", [
                                  sg.nightRange.max < 99
                                    ? _c("span", [
                                        _vm._v(
                                          "\n  \t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(sg.nightRange.min) +
                                            " - " +
                                            _vm._s(sg.nightRange.max) +
                                            " \n                                       "
                                        ),
                                        sg.nightRange.max > 1
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.voMsg("tpl.text.nights")
                                                )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        sg.nightRange.max == 1
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.voMsg("tpl.text.night")
                                                )
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  sg.nightRange.max > 99
                                    ? _c("span", [
                                        _vm._v(
                                          "\n  \t\t\t\t\t\t\t\t\t\t\t\t\t\t " +
                                            _vm._s(_vm.voMsg("tpl.startfrom")) +
                                            " " +
                                            _vm._s(sg.nightRange.min) +
                                            " " +
                                            _vm._s(
                                              _vm.voMsg("tpl.text.nights")
                                            ) +
                                            "\n  \t\t\t\t\t\t\t\t\t\t\t\t "
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            sg.guestRange
                              ? _c("span", [
                                  sg.guestRange.max > 99
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                                         " +
                                            _vm._s(_vm.voMsg("tpl.startfrom")) +
                                            " " +
                                            _vm._s(sg.guestRange.min) +
                                            " " +
                                            _vm._s(
                                              _vm.voMsg("tpl.text.persons")
                                            ) +
                                            "\n  \t\t\t\t\t\t\t\t\t\t\t\t\t"
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  sg.guestRange.max < 99
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(sg.guestRange.min) +
                                            " - " +
                                            _vm._s(sg.guestRange.min) +
                                            " " +
                                            _vm._s(
                                              _vm.voMsg("tpl.text.persons")
                                            ) +
                                            "\n  \t\t\t\t\t\t\t\t\t\t\t\t\t"
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          !sg.service.mandatory
                            ? _c("span", [
                                _vm._v(
                                  "(" +
                                    _vm._s(_vm.voMsg("tpl.text.optional")) +
                                    ")"
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          sg.service.mandatory
                            ? _c("span", [_c("sup", [_vm._v("*")])])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-end" }, [
                          _c("div", [
                            sg.service.calculation === "FLAT"
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.voMsg("tpl.service.FLAT")))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            sg.service.calculation === "NIGHT"
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.voMsg("tpl.service.NIGHT")))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            sg.service.calculation === "WEEK"
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.voMsg("tpl.service.WEEK")))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            sg.service.calculation === "MONTH"
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.voMsg("tpl.service.MONTH")))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            sg.service.perChild
                              ? _c("span", [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.voMsg("tpl.perChild")))
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            sg.service.perAdult
                              ? _c("span", [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.voMsg("tpl.perAdult")))
                                  ])
                                ])
                              : _vm._e()
                          ])
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-end price" }, [
                          sg.price
                            ? _c("span", { staticClass: "text-nowrap" }, [
                                _vm._v(_vm._s(_vm.getCalculatedPrice(sg.price)))
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          sg.service.calculation === "USAGE"
                            ? _c("span", { staticClass: "text-nowrap" }, [
                                _vm._v(_vm._s(_vm.voMsg("tpl.service.USAGE")))
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !sg.price &&
                          (sg.service._id == 41834 || sg.service._id == 41835)
                            ? _c("span", { staticClass: "tex-nowrap" }, [
                                _vm._v(
                                  "\n                                  0 €\n  \t\t\t\t\t\t\t\t\t\t\t"
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(sg.lines, function(price) {
                      return _vm.isDateInRange(price.fromdate, year)
                        ? _c("tr", { staticClass: "subrow" }, [
                            _c("td", { staticClass: "subinfo" }, [
                              price.fromdate
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getFormattedDate(price.fromdate)
                                      ) +
                                        " - " +
                                        _vm._s(
                                          _vm.getFormattedDate(price.tilldate)
                                        )
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("br"),
                              _c("small", [_vm._v(_vm._s(price.season))])
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-end calc" }, [
                              sg.service.calculation === "FLAT"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.voMsg("tpl.service.FLAT"))
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              sg.service.calculation === "NIGHT"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.voMsg("tpl.service.NIGHT"))
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              sg.service.calculation === "WEEK"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.voMsg("tpl.service.WEEK"))
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              sg.service.calculation === "MONTH"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.voMsg("tpl.service.MONTH"))
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              sg.service.calculation === "USAGE"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.voMsg("tpl.service.USAGE"))
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-end price" }, [
                              price.value
                                ? _c("span", { staticClass: "text-nowrap" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getCalculatedPrice(price.value)
                                      )
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              sg.service.calculation === "USAGE"
                                ? _c("span", { staticClass: "text-nowrap" }, [
                                    _vm._v(
                                      _vm._s(_vm.voMsg("tpl.service.USAGE"))
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ])
                        : _vm._e()
                    })
                  ],
                  2
                )
              }),
              0
            )
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "hint" }, [
      _c("sup", [_vm._v("*")]),
      _vm._v(_vm._s(_vm.voMsg("tpl.text.priceObligatory")))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }