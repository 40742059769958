<template>
    <div class="datepicker-trigger">

        <slot></slot>

        <airbnb-style-datepicker :trigger-element-id="triggerId"
            :close-after-select="true"
            :inline="inline"
            :show-shortcuts-menu-trigger="false"
            :show-action-buttons="false"
            :offset-y="10"
            :mode="'range'"
            :fullscreen-mobile="fullscreenMobile"
            :disabled-dates="disabledDays"
            :customized-dates="calClasses"
            :min-date="minDate"
            :end-date="maxDate"
            :date-one="from"
            :date-two="till"
            :months-to-show="months"
            @opened="onOpened"
            @closed="onClosed"
            :mobile-header="mobileHeader"
            @date-one-selected="onFromSelected"
            @date-two-selected="onTillSelected" />
    </div>

</template>

<script>
    import {
    	isBlank
    }
    from 'voUtils/tools.js';
    import V from 'voUtils/V.js'

    import DateEx from 'voUtils/DateEx.js'

    export default {
    	voVueComponent: 'voffice-daterange',
    	voMsgCodes: [
    		'weekdayslist.short',
    		'monthlist.full',
    		'weekdayslist',
    		'tpl.text.from',
    		'tpl.text.nights',
    		'tpl.text.until',
    		'tpl.text.minstay'

    	],
    	data() {
    		return {
    			needTill: false,
    			nextArrivalDate: undefined,
    			disabledArrivalDays: [],
    			disabledDepartureDays: [],
    			calClasses: [],
    			minStayValues: {},
    			priceValues: {},
    			calData: {},
    			personalSurcharge: undefined,
    			cal: undefined,
    			prices: undefined,
    		}
    	},
    	props: {
    		from: String,
    		till: String,
    		triggerId: String,
    		inline: Boolean,
    		fullscreenMobile: Boolean,
    		getCal: Function,
    		months: {
    			type: Number,
    			default: 2
    		},
    		scrollToPricesCalc: {
    			type: Boolean,
    			default: false
    		},
    		getPrices: Function
    	},

    	computed: {
    		disabledDays: function() {
    			return this.needTill ? this.disabledDepartureDays : this.disabledArrivalDays
    		},
    		mobileHeader: function() {
    			if (this.needTill) {
    				return this.voMsg("daterange.till");
    			} else {
    				return this.voMsg("daterange.from");
    			}
    		},
    		minDate: function() {
    			if (this.needTill) {
    				return DateEx.plusDays(this.from, 1);
    			} else {
    				return DateEx.interfaceFormat(DateEx.getToday());
    			}

    		},
    		maxDate: function() {
    			if (this.needTill) {
    				return this.nextArrivalDate;
    			} else {
    				return undefined;
    			}

    		},
    	},

    	methods: {
    		onOpened: function() {
    			if (VOFFICE.settings.showCalendarTooltips && (VOFFICE.settings.showCalendarTooltips.show && VOFFICE.settings.showCalendarTooltips.showprices)) {
    				if (this.getPrices && !this.prices) {
    					this.getPrices((prices) => {
    						this.prices = prices;

    						var from;
    						var till;
    						var diffDays;
    						var days;
    						var day;
    						var priceValues = {};
    						var services = {};
    						var personalSurcharge = null;

    						for (let a of prices.retailPrices) {
    							if (a.service.type === 'RENT' && a.service.surcharge && a.service.mandatory) {
    								if (a.guestRange && a.guestRange.min) {
    									if (!personalSurcharge) {
    										personalSurcharge = a.guestRange.min;
    									}
    								}

    							}
    							for (let b of a.lines) {
    								if (b.type === 'RENT') {
    									from = DateEx.interfaceFormat(b.fromdate);
    									till = DateEx.interfaceFormat(b.tilldate);
    									days = DateEx.getDays(b.tilldate, b.fromdate) - 1;
    									day = DateEx.localDate(b.fromdate);
    									//console.log('Service::', a.service);
    									for (var i = 0; i <= days; i++) {
    										if (!a.service.surcharge) {
    											priceValues[DateEx.interfaceFormat(day)] = {};
    											priceValues[DateEx.interfaceFormat(day)]['price'] = b.value;
    											if (b.nightRange) {
    												priceValues[DateEx.interfaceFormat(day)]['minnights'] = b.nightRange.min
    											}
    										}
    										day = DateEx.plusDays(day, 1)
    									}
    								}
    							}
    						}

    						this.priceValues = priceValues;
    						this.personalSurcharge = (personalSurcharge - 1);

    					});

    				}

    			}

    			if (this.getCal && !this.cal) {
    				this.getCal((cal) => {
    					this.cal = cal;
    					var day = DateEx.localDate(cal.availabilityUpdate.split('T')[0]);

    					var arrivals = [];
    					var departures = [];
    					var notAvailable = [];
    					var disabledArrivalDays = [];
    					var disabledDepartureDays = [];
    					var availableArrivalDays = [];
    					var calData = {};
    					var calDays = [];

    					var last;
    					for (let a of cal.availability) {
    						if (a == 'Y') {
    							if (last == 'N' || last == 'Q') {
    								departures.push(DateEx.interfaceFormat(day));
    							}
    						} else {
    							if (last == 'Y') {
    								arrivals.push(DateEx.interfaceFormat(day));
    							} else {
    								notAvailable.push(DateEx.interfaceFormat(day));
    							}
    						}

    						day = DateEx.plusDays(day, 1)
    						last = a;
    					}

    					if (VOFFICE.settings.showCalendarTooltips && (VOFFICE.settings.showCalendarTooltips.show && VOFFICE.settings.showCalendarTooltips.showminstay)) {

    						var day = DateEx.localDate(cal.availabilityUpdate.replaceAll('Z', ''));


    						for (let a of this.cal.minStay) {
    							const formattedDay = DateEx.interfaceFormat(day);
    							if (!calData[formattedDay]) {
    								calData[formattedDay] = {};
    							}
    							calData[DateEx.interfaceFormat(day)]['ms'] = a;
    							//minStayValues[DateEx.interfaceFormat(day)] = a;
    							day = DateEx.plusDays(day, 1)
    						}


    					}

    					this.calClasses = [{
    						cssClass: 'arrival',
    						dates: arrivals
    					}, {
    						cssClass: 'departure',
    						dates: departures
    					}, {
    						cssClass: 'not-available',
    						dates: notAvailable
    					}, {
    						cssClass: 'ar-pos',
    						dates: availableArrivalDays
    					}, {
    						cssClass: 'scope',
    						dates: calDays
    					}, {
    						cssClass: 'no-departure',
    						dates: disabledDepartureDays
    					}, {
    						cssClass: 'no-arrival',
    						dates: disabledArrivalDays
    					}];

    					var day = DateEx.localDate(cal.availabilityUpdate.split('T')[0]);
    					var today = DateEx.localDate(DateEx.getToday());
    					var el = '';

    					for (let a of cal.changeOver) {
    						const formattedDay = DateEx.interfaceFormat(day);
    						// no action possible
    						if (a == 'X') {
    							disabledArrivalDays.push(DateEx.interfaceFormat(day));
    						}
    						// O=check-out only    
    						if (a == 'O') {
    							disabledArrivalDays.push(DateEx.interfaceFormat(day));
    						}
    						// C=check-in/out
    						if (a == 'C' || a == 'I') {
    							availableArrivalDays.push(DateEx.interfaceFormat(day));
    						}

    						if (day >= DateEx.plusDays(today, 0)) {
    							calDays.push(DateEx.interfaceFormat(day));
    						}

    						if (!calData[formattedDay]) {
    							calData[formattedDay] = {};
    						}
    						calData[DateEx.interfaceFormat(day)]['co'] = a;

    						day = DateEx.plusDays(day, 1)
    					}

    					this.disabledArrivalDays = disabledArrivalDays;
    					var priceValues = this.priceValues;
    					this.calData = calData;
    					this.makeTooltip();

    				});


    			}
    		},
    		onClosed: function() {
    			setTimeout(() => {
    				this.$emit('closed');
    			}, 50);
    		},
    		onFromSelected: function(d) {
    			this.needTill = true;
    			this.$emit('update:from', d);
    			this.$emit('update:till', undefined);

    			if (this.cal) {

    				//nextArrivalDate
    				let day = DateEx.localDate(this.cal.availabilityUpdate.split('T')[0]);
    				let from = DateEx.localDate(d);

    				let nextArrivalDate;
    				for (let a of this.cal.availability) {
    					if (day > from && a != 'Y') {
    						nextArrivalDate = day;

    						break;
    					}
    					day = DateEx.plusDays(day, 1);
    				}

    				this.nextArrivalDate = DateEx.interfaceFormat(nextArrivalDate);

    				day = DateEx.localDate(this.cal.availabilityUpdate.split('T')[0]);

    				var disabledDepartureDays = [];

    				for (let a of this.cal.changeOver) {
    					if (nextArrivalDate && (day > nextArrivalDate)) {
    						disabledDepartureDays.push(DateEx.interfaceFormat(day));
    					} else if (day > from && (a == 'X' || a == 'I')) {
    						disabledDepartureDays.push(DateEx.interfaceFormat(day));
    					}
    					day = DateEx.plusDays(day, 1)
    				}

    				day = DateEx.localDate(this.cal.availabilityUpdate.split('T')[0]);
    				var diffDays;
    				for (let a of this.cal.minStay) {
    					if (nextArrivalDate && (day > nextArrivalDate)) {
    						break;
    					} else if (day > from) {
    						diffDays = DateEx.getDays(day, from);
    						if (diffDays < a) {
    							disabledDepartureDays.push(DateEx.interfaceFormat(day));
    						}
    					}
    					day = DateEx.plusDays(day, 1)
    				}
    				this.disabledDepartureDays = disabledDepartureDays;

    			}

    		},
    		onTillSelected: function(d) {
    			this.needTill = isBlank(d) && this.from;
    			this.$emit('update:till', d);

    			setTimeout(() => {
    				this.$emit('on-tilldate-selected', {
    					scrollToPricesCalc: this.scrollToPricesCalc
    				});
    			}, 50);

    		},

    		isNeedTill: function() {
    			var needTill = this.needTill;
    			if (needTill.length > 0) {
    				return true;
    			}
    			return false;
    		},


    		makeTooltip: function() {
    			if (VOFFICE.settings && VOFFICE.settings.showCalendarTooltips && (!VOFFICE.settings.showCalendarTooltips.show)) {
    				return;
    			}
    			const that = this;
    			var calData = this.calData;
    			var priceValues = this.priceValues;
    			var personalSurcharge = this.personalSurcharge;

    			var minstayText = this.voMsg("tpl.text.minstay");
    			var nightsText = this.voMsg("tpl.text.nights");
    			var fromText = this.voMsg("tpl.text.from");
    			var forText = this.voMsg("tpl.for");
    			var personsText = this.voMsg("tpl.text.persons");
    			var availableNoArrivalPossible = this.voMsg("tpl.text.availableNoArrivalPossible");
    			var availableNoDeparturePossible = this.voMsg("tpl.text.availableNoDeparturePossible");
    			var arrivalPossible = this.voMsg("tpl.text.arrivalPossible");
    			var departurePossible = this.voMsg("tpl.text.departurePossible");
    			var departureNotPossible = this.voMsg("tpl.text.departureNotPossible");
    			var availableOnyDeparture = this.voMsg("tpl.text.availableOnyDeparture");
    			var alreadyOccupied = this.voMsg("tpl.text.alreadyOccupied");

    			$('body').on('mouseover click', '.asd__day', function(e) {
    				var pickdate = ''
    				var title = '';
    				var price;
    				var cur = VOFFICE.currency || '&euro;';
    				var currency = cur.replace(/eur/i, "&euro;");
    				var pickDate = undefined;
    				var nextArrivalDate = undefined;
    				pickdate = $(this).attr('data-date');

    				if (pickdate && that.nextArrivalDate) {
    					pickDate = new Date(pickdate);
    					nextArrivalDate = new Date(that.nextArrivalDate);
    				}
    				//console.log('this.nextArrivalDate::', that.nextArrivalDate);

    				if (pickdate) {
    					title += '<span class="info"><i class="fa-sharp fa-light fa-circle-info"></i></span><span class="date">' + that.voFormatDate(pickdate, 'withDay') + '</span><br/>';
    				}

    				//title = DateEx.formatDate(pickdate, undefined, VOFFICE.mlang);


    				if ($(this).hasClass('asd__day--not-available')) {
    					title += alreadyOccupied;
    				} else {
    					if (pickdate && calData[pickdate] && calData[pickdate].co) {
    						//	console.log('needTill::', that.isNeedTill());
    						//console.log('changeOver::', calData[pickdate].co);

    						if (calData[pickdate].co == 'X') {
    							if (!that.isNeedTill()) {
    								title += "&bullet; " + availableNoArrivalPossible;
    							}
    							if (that.isNeedTill()) {
    								if (pickDate && nextArrivalDate && pickDate <= nextArrivalDate) {
    									title += "&bullet; " + availableNoDeparturePossible;
    								} else {
    									title += "&bullet; " + availableNoDeparturePossible;
    								}
    							}
    						} else if (calData[pickdate].co === 'C') {
    							if (!that.isNeedTill()) {
    								title += "&bullet; " + arrivalPossible;
    							}
    							if (that.isNeedTill()) {
    								if (!$(this).hasClass('asd__day--disabled')) {
    									if (pickDate && nextArrivalDate && pickDate <= nextArrivalDate) {
    										title += "&bullet; " + departurePossible;
    									} else {
    										title += "&bullet; " + departurePossible;
    									}
    								} else if ($(this).hasClass('asd__day--disabled')) {
    									title += "&bullet; " + availableNoDeparturePossible;

    								}
    							}
    						} else if (calData[pickdate].co === 'O') {
    							if (!that.isNeedTill()) {
    								title += "&bullet; " + availableOnyDeparture;
    							}
    							if (that.isNeedTill()) {
    								if (!$(this).hasClass('asd__day--disabled')) {
    									if (pickDate && nextArrivalDate && pickDate <= nextArrivalDate) {
    										title += "&bullet; " + departurePossible;
    									} else {
    										title += "&bullet; " + departurePossible;
    									}
    								} else if ($(this).hasClass('asd__day--disabled')) {
    									title += "&bullet; " + availableNoArrivalPossible;
    								}
    							}
    						} else if (calData[pickdate].co === 'I') {
    							if (!that.isNeedTill()) {
    								title += "&bullet; " + arrivalPossible
    							}
    							if (that.isNeedTill()) {
    								if (pickDate && nextArrivalDate && pickDate <= nextArrivalDate) {
    									title += "&bullet; " + departureNotPossible;
    								}
    							}

    						}

    						if (that.isNeedTill() && pickDate && nextArrivalDate && pickDate > nextArrivalDate) {
    							title += "&bullet; " + departureNotPossible;
    						}


    					}
    					if (pickdate && calData[pickdate] && calData[pickdate].ms) {
    						title += '<br/> &bullet; ' + minstayText + ": " + calData[pickdate].ms + ' ' + nightsText;
    					}
    					if (pickdate && that.priceValues[pickdate]) {
    						price = (Math.round((that.priceValues[pickdate].price / 100) * 100) / 100);
    						if (calData[pickdate] && calData[pickdate].ms) {
    							title += " &bullet; " + fromText + " " + price.toLocaleString(undefined, {
    								minimumFractionDigits: 2,
    								maximumFractionDigits: 2
    							}) + ' ' + currency;
    						} else {
    							title += price.toLocaleString(undefined, {
    								minimumFractionDigits: 2,
    								maximumFractionDigits: 2,
    							}) + ' ' + currency;
    						}
    						if (that.priceValues[pickdate].minnights) {
    							//title += " " + fromText + " " + that.priceValues[pickdate].minnights + ' ' + nightsText;
    							if (personalSurcharge) {
    								title += " " + forText + " " + personalSurcharge + ' ' + personsText;
    							}
    						} else {
    							if (personalSurcharge) {
    								title += " " + forText + " " + personalSurcharge + ' ' + personsText;
    							}
    						}
    					}
    				}

    				$('#calInfo').html(title);

    			});


    		}
    	}
    }
</script>


<style scoped>
    .asd__wrapper--full-screen {
    	z-index: 9999;
    }

    .asd__day-button {
    	pointer-events: none;
    }

    .asd__day {
    	opacity: 1 !important;
    }

    .asd__day--disabled,
    .asd__day--empty {
    	opacity: 1 !important;
    }
</style>